import React from "react";
import { Wrapper } from "./styled";

const ImageWithText = ({ layout, image, imageMobile, text }) => {
	return (
		<Wrapper layout={layout}>
			<figure>
				{layout === "big" && <img src={image} alt={text} />}
				<img src={imageMobile} alt={text} />
			</figure>
			<h2>{text}</h2>
		</Wrapper>
	);
};

export default ImageWithText;
