import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
	position: relative;
	overflow: hidden;
	margin-bottom: 12px;
	@media ${device.tablet} {
		margin-bottom: 0;
	}
	figure {
		width: 100%;
		height: 100%;
		margin: auto;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			${(props) =>
				props.layout === "big" &&
				css`
					&:first-of-type {
						display: none;
					}
					&:last-of-type {
						display: block;
					}
					@media ${device.tablet} {
						&:first-of-type {
							display: block;
						}
						&:last-of-type {
							display: none;
						}
					}
				`}
		}
	}
	h2 {
		position: absolute;
		top: 1.2em;
		left: 1em;
		right: 1em;

		color: ${colors.white};
		font-size: 20px;
		line-height: 1.2;
		font-family: "AudiType-Extended";
		font-weight: 700;
		margin: 0;
		@media ${device.tablet} {
			font-size: 36px;
		}

		@media ${device.laptopL} {
			font-size: 50px;
		}
	}
`;
