import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
  outline: none;
  background-color: ${colors.white};
  box-sizing: border-box;
  padding: 36px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${colors.gray2};
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  @media ${device.laptop} {
    padding: 38px 22px;
  }
  @media ${device.laptopL} {
    padding: 40px 24px;
  }

  h3 {
    color: ${colors.black};
    font-family: "AudiType-Extended";
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 20px;
    @media ${device.laptop} {
      font-size: 21px;
    }
    @media ${device.laptopL} {
      font-size: 32px;
      margin-bottom: 14px;
    }
  }

  p {
    color: ${colors.grayDark};
    font-family: "AudiType";
    font-size: 14px;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 20px;
    @media ${device.laptop} {
      font-size: 15px;
    }
    @media ${device.laptopL} {
      font-size: 16px;
    }
  }

  &:hover {
    border: 1px solid ${colors.grayDark};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const InfoBlock = styled.div``;

export const InfoExtra = styled.div`
  margin-bottom: 20px;
  min-height: 70px;
`;

export const PriceText = styled.div`
  color: ${colors.black};
  font-family: "AudiType-Extended";
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  margin-bottom: 12px;

  span {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 18px;
  }

  p {
    display: inline-block;
    font: inherit;
    color: inherit;
    margin: 0;
  }
`;

export const PriceDetail = styled.div`
  color: ${colors.black};
  font-size: ${props => props.fontSize ? `${props.fontSize}px` : "16px"};
  line-height: 1.25;

  p {
    font: inherit;
    color: inherit;
    margin: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;

  div {
    padding: 0 6px;
    margin-bottom: 6px;
    box-sizing: border-box;
    flex: 0 0 100%;
    max-width: 100%;
    @media ${device.tabletL} {
      margin-bottom: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }

    a {
      padding: 0 4px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 44px;
      @media ${device.laptopL} {
        height: 70px;
      }
    }
  }
`;
