import React from "react";
import Slider from "react-slick";
import "../../../assets/css/slick/slick.min.css";
import { Wrapper } from "./styled";
import PromocionItem, { PromocionItemFree } from "../PromocionItem";
import SliderArrow from "../SliderArrow";
import SliderDots from "../SliderDots";
import API from "../../../classes/api";

const PromocionesSlider = ({ carline, salesgroup }) => {
	const slideSettings = {
		dots: true,
		arrows: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		infinite: false,
		nextArrow: <SliderArrow color="dark" position="next" outside={true} />,
		prevArrow: <SliderArrow color="dark" position="prev" outside={true} />,
		appendDots: (dots) => <SliderDots dots={dots} />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const ofertasTipos = ["renting", "financiacion-lineal", "opcion-futuro"];
	const promocionesItems = [];

	ofertasTipos.forEach((ofertaTipo) => {
		const mejorOferta = API.findMejorOfertaFromSalesgroupByTipo(
			ofertaTipo,
			salesgroup,
		);
		mejorOferta &&
			promocionesItems.push(
				<PromocionItem
					carline={carline}
					salesgroup={salesgroup}
					oferta={mejorOferta}
				/>,
			);
	});

	const buttons = [];

	const stockNuevosButton = API.findBotonById(
		salesgroup.botones,
		"stock-nuevos",
	);

	if (stockNuevosButton && stockNuevosButton.url) {
		buttons.push({
			...stockNuevosButton,
			dataVehiculosNuevos: "vehiculos-nuevos",
			dataFinanciacion: "vehiculos-en-stock",
		});
	}

	const stockOcasionButton = API.findBotonById(
		salesgroup.botones,
		"stock-ocasion",
	);

	if (stockOcasionButton && stockOcasionButton.url) {
		buttons.push({
			...stockOcasionButton,
			dataVehiculosOcasion: "vehiculos-ocasion",
			dataFinanciacion: "vehiculos-en-stock",
		});
	}

	if (buttons.length > 0) {
		promocionesItems.push(
			<PromocionItemFree
				titulo="Vehículos en stock"
				texto="Elige uno de nuestros vehículos en stock y empieza a conducirlo de forma inmediata y con el mejor precio."
				botones={buttons}
				forceBlank={true}
			/>,
		);
	}

	return (
		<Wrapper>
			<Slider {...slideSettings}>{promocionesItems}</Slider>
		</Wrapper>
	);
};

export default PromocionesSlider;
