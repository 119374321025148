import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
	.slick-slider {
		background-color: black;
		height: 100%;
		box-sizing: border-box;

		.slick-list {
			height: 100%;
			.slick-track {
				height: 100%;
				.slick-slide {
					outline: none !important;
					> div {
						height: 100%;
					}
				}
			}
		}
	}
`;

export const SliderItem = styled.div`
	position: relative;
	outline: none;
	box-sizing: border-box;
	padding: 20px 5px;
	height: 100%;
	color: ${colors.white};
	@media ${device.tablet} {
		padding: 45px 5px;
	}
	@media ${device.laptopL} {
	}
	figure {
		z-index: 0;
		margin: 0;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
`;

export const SliderInfo = styled.div`
	//display: flex;
	//flex-wrap: wrap;
	//flex-direction: column;
	//justify-content: space-around;
	height: 100%;
	position: relative;
	z-index: 1;
	padding: 0 20px;
	@media ${device.tablet} {
		padding: 0 40px;
	}
	@media ${device.laptopS} {
		padding: 0 60px;
	}
	> div {
		overflow: hidden;
		height: calc(100% - 126px);
		@media ${device.tablet} {
			height: calc(100% - 58px);
		}
	}
	h2 {
		margin: 0;
		font-size: 20px;
		line-height: 1.25;
		margin-bottom: 20px;
		font-family: "AudiType-Extended";
		font-weight: 700;
		@media ${device.tablet} {
			margin-bottom: 10px;
		}
		@media ${device.laptopS} {
			font-size: 25px;
		}
		@media ${device.laptop} {
			font-size: 30px;
		}
		@media ${device.laptopL} {
			font-size: 35px;
		}
	}
	h3 {
		font-family: "AudiType-Extended";
		font-weight: 400;
		font-size: 16px;
		line-height: 1.2;
		margin: 0 0 6px;
		@media ${device.tablet} {
			font-size: 18px;
		}
		@media ${device.laptop} {
			font-size: 24px;
		}
		@media ${device.laptopL} {
			font-size: 28px;
		}
	}
	p {
		margin: 0;
		font-size: 15px;
		line-height: 1.5;
		font-family: "AudiType-Extended";
		@media ${device.laptopS} {
			margin-bottom: 50px;
		}
		@media ${device.laptopL} {
			font-size: 20px;
		}
	}
`;

export const PriceWrapper = styled.div`
	margin-bottom: 20px;
	@media ${device.tablet} {
		margin-bottom: 0;
	}
	p {
		font-family: "AudiType-Extended";
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		margin: 0;
		@media ${device.tablet} {
			font-size: 20px;
		}
		@media ${device.laptopL} {
			font-size: 25px;
		}
	}
	span {
		font-size: 15px;
		line-height: 1.5;
		font-family: "AudiType-Extended";
		@media ${device.laptopL} {
			font-size: 20px;
		}
	}
`;

export const ButtonsWrapper = styled.div`
	height: 126px !important;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: -6px;
	margin-right: -6px;
	@media ${device.tablet} {
		height: auto !important;
	}
	div {
		box-sizing: border-box;
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 0;
		}
		a {
			padding: 0;
			height: 58px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
		@media ${device.tablet} {
			padding: 0 6px;
			flex: 0 0 50%;
			max-width: 260px;
			margin-bottom: 0;
		}
		@media ${device.laptopL} {
		}
	}
`;
