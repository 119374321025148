import React from "react";
import Slider from "react-slick";
import "../../../assets/css/slick/slick.min.css";
import {
	Wrapper,
	SliderItem,
	SliderInfo,
	PriceWrapper,
	ButtonsWrapper,
} from "./styled";
import SliderArrow from "../SliderArrow";
import { CustomButton } from "../../../assets/styles/commonStyles";

const TextSlider = ({ items }) => {
	const slideSettings = {
		dots: false,
		arrows: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		prevArrow: <SliderArrow color="light" layout="in-out" position="prev" />,
		nextArrow: <SliderArrow color="light" layout="in-out" position="next" />,
	};

	return (
		<Wrapper>
			<Slider {...slideSettings}>
				{items.map((item) => (
					<SliderItem key={item.id}>
						{item.bgImg && (
							<figure>
								<img src={item.bgImg} alt={item.text} />
							</figure>
						)}
						<SliderInfo>
							<div>
								{item.preTitle && <h3>{item.preTitle}</h3>}
								<h2>{item.text}</h2>
								{(item.price || item.priceNote) && (
									<PriceWrapper>
										{item.price && <p>{item.price}</p>}
										{item.priceNote && <span>{item.priceNote}</span>}
									</PriceWrapper>
								)}
							</div>
							<ButtonsWrapper>
								{item.buttons.map((button, index) => (
									<div key={index}>
										<CustomButton
											layout={index === 0 ? "light" : "light3"}
											href={button.url}
											target={
												button.nuevaVentana ? "_blank" : "_top"
											}
										>
											{button.text}
										</CustomButton>
									</div>
								))}
							</ButtonsWrapper>
						</SliderInfo>
					</SliderItem>
				))}
			</Slider>
		</Wrapper>
	);
};

export default TextSlider;
