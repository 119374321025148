import React from "react";
import {
	Wrapper,
	InfoExtra,
	PriceText,
	PriceDetail,
	InfoBlock,
	ButtonsWrapper,
} from "./styled";
import { CustomButton } from "../../../assets/styles/commonStyles";
import { descripciones } from "../../../data/descripciones";
import API from "../../../classes/api";
import { Link } from "gatsby";

const PromocionItem = ({ carline, salesgroup, oferta }) => {
	if (!oferta) return null;
	const slug = oferta.tipo?.replace("-", "");
	// console.log("slug", slug);
	// console.log("carline" ,carline)
	// console.log("salesgroup" ,salesgroup)
	// console.log("oferta", oferta, oferta.oferta);

	const getPriceNote = (oferta) => {
		if (slug === "opcionfuturo") {
			return (
				<PriceDetail fontSize={14}>
					<p>
						{oferta?.cuotaEntrada ? (
							<span>
								{API.formatPrecio(oferta?.cuotaEntrada)}€ de
								entrada&nbsp;|&nbsp;
							</span>
						) : (
							<span>Sin entrada&nbsp;|&nbsp;</span>
						)}
						{oferta?.cuotaFinal && (
							<span>
								{API.formatPrecio(oferta?.cuotaFinal)}€ cuota final
							</span>
						)}
					</p>
					<p>
						<span>{oferta.plazos} cuotas&nbsp;|&nbsp;</span>
						<span>
							<strong>
								<u>{API.formatPrecio(oferta.tae)}% TAE</u>
							</strong>
							&nbsp;|&nbsp;
						</span>
						<span>{API.formatPrecio(oferta.tin)}% TIN</span>
					</p>
				</PriceDetail>
			);
		} else if (slug === "renting") {
			return (
				<PriceDetail>
					<p>
						{oferta?.cuotaEntrada ? (
							<span>
								{API.formatPrecio(oferta?.cuotaEntrada)}€
								entrada&nbsp;|&nbsp;
							</span>
						) : (
							<span>Sin entrada&nbsp;|&nbsp;</span>
						)}
						<span>{oferta.plazos} cuotas</span>
					</p>
				</PriceDetail>
			);
		}
	};

	return (
		<Wrapper>
			<InfoBlock>
				<h3>{descripciones[slug].title}</h3>
				<p>{descripciones[slug].desc}</p>
			</InfoBlock>
			<InfoBlock>
				<InfoExtra>
					<PriceText>
						{oferta.modalidad && <span>{oferta.modalidad}&nbsp;</span>}
						{!oferta.modalidad && <span>&nbsp;</span>}
						<p>
							{API.formatPrecio(oferta.precio, false)}€
							{oferta.periodicidad ? `/${oferta.periodicidad}` : ``}
							<a
								style={{ color: "inherit", textDecoration: "none" }}
								href={API.buildURL(carline, salesgroup, oferta.tipo)}
								target="_top"
								data-btn_sabermas="saber_mas"
								data-financiacion={oferta.tipo}
							>
								*
							</a>
						</p>
					</PriceText>
					{getPriceNote(oferta)}
				</InfoExtra>
				<ButtonsWrapper>
					<div>
						<CustomButton
							layout="light2"
							href={API.buildURL(carline, salesgroup, oferta.tipo)}
							target="_top"
							data-btn_sabermas="saber_mas"
							data-financiacion={oferta.tipo}
						>
							Saber más
						</CustomButton>
					</div>
					{oferta.botones?.map((boton, index) => (
						<div key={index}>
							<CustomButton
								layout="light2"
								href={boton.url}
								target={boton.nuevaVentana ? "_blank" : "_top"}
								data-btn_solicitaroferta="solicitar_oferta"
								data-financiacion={oferta.tipo}
							>
								{boton.etiqueta}
							</CustomButton>
						</div>
					))}
				</ButtonsWrapper>
			</InfoBlock>
		</Wrapper>
	);
};

const PromocionItemFree = ({ titulo, texto, botones, forceBlank }) => {
	return (
		<Wrapper>
			<InfoBlock>
				<h3>{titulo}</h3>
				<p>{texto}</p>
			</InfoBlock>
			<InfoBlock>
				<InfoExtra>
					<span>&nbsp;</span>
					<p>&nbsp;</p>
				</InfoExtra>
				<ButtonsWrapper>
					{botones.map((boton, index) => {
						return (
							boton.url && (
								<div key={index}>
									<CustomButton
										layout="light2"
										href={boton.url}
										target={
											forceBlank
												? "_blank"
												: boton.nuevaVentana
												? "_blank"
												: "_top"
										}
										data-vehiculos_nuevos={boton.dataVehiculosNuevos}
										data-vehiculos_ocasion={
											boton.dataVehiculosOcasion
										}
										data-financiacion={boton.dataFinanciacion}
									>
										{boton.etiqueta}
									</CustomButton>
								</div>
							)
						);
					})}
				</ButtonsWrapper>
			</InfoBlock>
		</Wrapper>
	);
};

export default PromocionItem;
export { PromocionItemFree };
