import styled, { css } from "styled-components";
import colors from "../../assets/styles/colors";
import { device } from "../../assets/styles/size";

export const Container = styled.div`
	position: relative;

	.slick-slider {
		.slick-list {
			margin: 0;
			position: relative;
			@media ${device.tablet} {
				margin: 0 18px;
			}
			.slick-track {
				margin: auto;
			}

			.slick-slide {
				outline: none !important;
			}
		}
	}
`;

export const RowTop = styled.div`
	border-bottom: 1px solid ${colors.gray};
	padding-bottom: 20px;
	margin-bottom: 20px;
	@media ${device.tablet} {
	}
	.slick-slider {
		.slick-list {
		}
	}
`;

export const RowBot = styled.div`
	transition: opacity 0.3s ease, visibility 0.3s ease;
	h3 {
		font-size: 24px;
		line-height: 1.2;
		font-family: "AudiType-Extended";
		font-weight: 700;
		text-align: center;
		margin-top: 0;
		margin-bottom: 24px;
	}
`;

export const ArrowDown = styled.div`
	width: 45px;
	height: 45px;
	margin: 0 auto 20px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	border: 1px solid ${colors.black};
	background-color: ${colors.black};
	transition: background-color 0.3s ease;

	div {
		position: absolute;
		top: 46%;
		left: 50%;
		border: solid white;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 5px;
		transform: translateY(-50%) translateX(-50%) rotate(45deg);
	}
`;

export const CarsList = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 18px;
	width: calc(100% - 36px);

	> div {
		box-sizing: border-box;
		margin-bottom: 20px;
		max-width: ${(props) =>
			props.itemsPerRow ? `${100 / props.itemsPerRow}%` : "16.6666%"};
		flex: ${(props) =>
			props.itemsPerRow ? `0 0 ${100 / props.itemsPerRow}%` : "16.6666%"};
	}
`;

export const CloseBtn = styled.div`
	cursor: pointer;
	width: 45px;
	height: 45px;
	margin: 0 auto 20px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	border: 1px solid ${colors.black};
	background-color: ${colors.black};
	transition: background-color 0.3s ease;

	div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		width: 14px;
		height: 14px;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 46%;
			width: 100%;
			height: 1px;
			background-color: ${colors.white};
			transform-origin: center;
			transition: background-color 0.3s ease;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	&:hover {
		background-color: ${colors.white};

		div {
			&:before,
			&:after {
				background-color: ${colors.black};
			}
		}
	}
`;
