import React, { useEffect } from "react";
import { Wrapper } from "./styled";
import ImageWithText from "../common/ImageWithText";
import TextSlider from "../common/TextSlider";

const BannerPromocion = (props) => {
	const { image, text, sliderItems, imageMobile } = props;
	return (
		<Wrapper hasSlider={sliderItems.length > 0 ? true : false}>
			<ImageWithText
				layout={sliderItems.length > 0 ? "small" : "big"}
				image={image}
				imageMobile={imageMobile}
				text={text}
			/>
			{sliderItems.length > 0 && <TextSlider items={sliderItems} />}
		</Wrapper>
	);
};

export default BannerPromocion;
