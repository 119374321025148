import styled, { css } from "styled-components";
import { device } from "../../assets/styles/size";

export const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-left: -8px;
	margin-right: -8px;
	margin-bottom: 16px;
	@media ${device.tablet} {
		height: 300px;
	}
	@media ${device.laptopS} {
		margin-left: -12px;
		margin-right: -12px;
		margin-bottom: 24px;
		height: 325px;
	}
	@media ${device.laptop} {
		height: 355px;
	}
	> div {
		box-sizing: border-box;
		padding-left: 8px;
		padding-right: 8px;
		min-height: 240px;
		flex: 0 0 100%;
		max-width: 100%;
		@media ${device.tablet} {
			height: 100%;
		}
		@media ${device.laptopS} {
			padding-left: 12px;
			padding-right: 12px;
		}

		${(props) =>
			props.hasSlider
				? css`
						@media ${device.tablet} {
							&:nth-of-type(1) {
								flex: 0 0 50%;
								max-width: 50%;
							}
							&:nth-of-type(2) {
								flex: 0 0 50%;
								max-width: 50%;
							}
						}
						@media ${device.laptopS} {
							&:nth-of-type(1) {
								flex: 0 0 40%;
								max-width: 40%;
							}
							&:nth-of-type(2) {
								flex: 0 0 60%;
								max-width: 60%;
							}
						}
				  `
				: css`
						&:nth-of-type(1) {
							flex: 0 0 100%;
							max-width: 100%;
						}
				  `};
	}
`;
