const descripciones = {
	renting: {
		title: "Renting",
		desc:
			"Un Renting es un alquiler con cómodas cuotas que incluye el mantenimiento, seguro a todo riesgo y asistencia en carretera 24 horas.",
	},
	financiacionlineal: {
		title: "Financiación lineal",
		desc:
			"Compra tu Audi de la forma tradicional y benefíciate de una promoción especial por financiarlo con Audi Financial Services.",
	},
	opcionfuturo: {
		title: "Audi Opción",
		desc:
			"Adquiere tu Audi con la posibilidad de devolverlo en el futuro con un valor de recompra asegurado por Audi o la posibilidad de cambiarlo por uno nuevo.",
	},
};

export { descripciones };