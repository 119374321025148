import React, { useEffect, useState, useRef } from "react";
import { GlobalStyle } from "../assets/styles/globalStyles";
import {
	StyledSection,
	StyledSectionInner,
} from "../assets/styles/commonStyles";
import colors from "../assets/styles/colors";
import SelectModel from "../components/common/SelectModel";
import CarsModelSlider from "../components/CarsModelSlider";
import BannerPromocion from "../components/BannerPromocion";
import PromocionesSlider from "../components/common/PromocionesSlider";
import { connect } from "react-redux";
import {
	getCarlines,
	setCarline,
	setSalesgroup,
} from "../redux/carlines/actions";
import ComponentLoading from "../components/common/ComponentLoading";
import Layout from "../components/common/Layout";
import API from "../classes/api";
import { dataLayerPush } from "../classes/gtm";

const IndexPage = ({
	carlines,
	carline,
	salesgroup,
	getCarlines,
	setCarline,
	setSalesgroup,
}) => {
	const bannerSectionRef = useRef();
	const [isFirstTime, setIsFirstTime] = useState(true);
	const [currentCarlineId, setCurrentCarlineId] = useState(null);

	useEffect(() => {
		if (!carlines || carlines.length === 0) getCarlines({ isEmpresa: false });
	}, []);

	useEffect(() => {
		if (salesgroup) {
			if (!isFirstTime) {
				if (currentCarlineId === carline.id) {
					if (bannerSectionRef.current) {
						window.scrollTo({
							behavior: "smooth",
							top: bannerSectionRef.current.offsetTop,
						});
					}
				}
			} else {
				setIsFirstTime(false);
			}
		}
	}, [salesgroup]);

	useEffect(() => {
		setCurrentCarlineId(carline?.id);
	}, [carline]);

	const handleSelectChange = (data) => {
		setCarline(data.carline);
		setSalesgroup(data.salesgroup);
	};

	// gtm

	useEffect(() => {
		if (carline && salesgroup) {
			dataLayerPush({
				seccion: "promociones",
				pagina: "home",
				gama: carline.codigo,
				modelo: salesgroup.codigo,
				precio: salesgroup.pvpRecomendado,
				utm_content: "",
				modelo_edicion: "",
			});
		}
	}, [carline, salesgroup]);

	return (
		<Layout>
			<main>
				<GlobalStyle />

				<StyledSection>
					<StyledSectionInner noPaddingTop={true}>
						<div>
							<SelectModel
								carlines={carlines}
								onChange={handleSelectChange}
							/>
							{!carlines && <ComponentLoading />}
							{carlines && (
								<CarsModelSlider
									carlines={carlines}
									onSalesgroupChange={(carline, salesgroup) => {
										setCarline(carline);
										setSalesgroup(salesgroup);
									}}
								/>
							)}
						</div>
					</StyledSectionInner>
				</StyledSection>

				{salesgroup && (
					<StyledSection ref={bannerSectionRef} bgColor={colors.grayLight}>
						<StyledSectionInner noPaddingTopBot={true}>
							<BannerPromocion
								image={salesgroup.imagenes?.editorial?.l}
								imageMobile={salesgroup.imagenes?.editorialMobile?.l}
								text={`${salesgroup.nuevo ? "Nuevo" : ""} Audi ${
									carline.titulo
								} ${salesgroup.titulo}`}
								sliderItems={(() => {
									const banners = API.findBanners(
										carline,
										salesgroup,
										null,
										true,
									);
									const items = banners.map((banner) => {
										const withMoreData = [
											"financiacion-lineal",
											"opcion-futuro",
											"renting",
										];
										if (withMoreData.indexOf(banner.tipo) > -1) {
											return {
												id: banner.id,
												preTitle: `${
													salesgroup.nuevo ? "Nuevo" : ""
												} Audi ${carline.titulo} ${
													salesgroup.titulo
												}`,
												text: banner.trimlevel.titulo,
												price: `Por ${API.formatPrecio(
													banner.precio,
													false,
												)}€/${banner.periodicidad}`,
												priceNote: "Con entrada. IVA incluído.",
												// featuresList: [],
												buttons: (() => {
													if (banner.botones) {
														return banner.botones.map((boton) => {
															return {
																text: boton.etiqueta,
																url: boton.url,
																nuevaVentana:
																	boton.nuevaVentana,
															};
														});
													} else return null;
												})(),
											};
										} else {
											return {
												id: banner.id,
												text: banner.tituloBanner,
												bgImg: banner.imagenes?.principal?.l,
												buttons: (() => {
													if (banner.botones) {
														return banner.botones.map((boton) => {
															return {
																text: boton.etiqueta,
																url: boton.url,
																nuevaVentana:
																	boton.nuevaVentana,
															};
														});
													} else return null;
												})(),
											};
										}
									});
									return items;
								})()}
							/>
							<PromocionesSlider
								carline={carline}
								salesgroup={salesgroup}
							/>
						</StyledSectionInner>
					</StyledSection>
				)}
			</main>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { carlines, carline, salesgroup } = state.carlines;
	return {
		carlines,
		carline,
		salesgroup,
	};
};

const mapDispatchToProps = {
	getCarlines,
	setCarline,
	setSalesgroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
