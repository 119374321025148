import React, { useState } from "react";
import "../../assets/css/slick/slick.min.css";
import {
	Container,
	RowTop,
	RowBot,
	ArrowDown,
	CarsList,
	CloseBtn,
} from "./styled";
import CarModelItem from "../common/CarModelItem";
import { connect } from "react-redux";
import { setCarline } from "../../redux/carlines/actions";
import SliderCustom from "../common/SliderCustom";
import API from "../../classes/api";

const CarsModelSlider = ({
	carlines,
	carline,
	salesgroup,
	setCarline,
	onSalesgroupChange,
	mode = null,
}) => {
	const handleSalesgroupClick = (carline, salesgroup) => {
		onSalesgroupChange(carline, salesgroup);
	};

	const [itemsPerRow, setItemsPerRow] = useState(null);

	return (
		<Container>
			<RowTop>
				{/* aca aparecen los carlines */}
				<SliderCustom
					items={carlines}
					onItemSelected={(carline) => {
						setCarline(carline);
					}}
					itemActive={carline ? carline.id : null}
					setItemsPerRow={setItemsPerRow}
					mode={mode}
				/>
			</RowTop>
			{carline && (
				// aca aparecen los salesgroups
				<RowBot>
					<ArrowDown>
						<div />
					</ArrowDown>
					<h3>Audi {carline.titulo}</h3>
					{/* lista de salesgroups */}
					<CarsList itemsPerRow={itemsPerRow}>
						{carline.salesgroups?.map((s) => {
							return (
								<CarModelItem
									key={s.id}
									title={`${
										s.titulo
											? carline.titulo + " " + s.titulo
											: carline.titulo
									}`}
									desc={
										mode === "renting-empresa"
											? `Por ${API.formatPrecio(
													API.findMejorOfertaFromSalesgroup(
														s,
														"renting",
													).precio,
													false,
											  )}€/${
													API.findMejorOfertaFromSalesgroup(
														s,
														"renting",
													).periodicidad
											  }`
											: `Desde ${API.formatPrecio(
													s.pvpRecomendado,
													false,
											  )}€`
									}
									image={s.imagenes?.lateral?.s}
									imageHover={s.imagenes?.tresCuartos?.s}
									factorImg={carline.imagenes?.escala}
									onClick={() => handleSalesgroupClick(carline, s)}
									active={salesgroup ? s.id === salesgroup.id : false}
									dataModelName={salesgroup.codigo}
									dataModelInfo={salesgroup.titulo}
									dataModelPrice={salesgroup.pvpRecomendado}
								/>
							);
						})}
					</CarsList>
				</RowBot>
			)}
		</Container>
	);
};

const mapStateToProps = (state) => {
	const { carline, salesgroup } = state.carlines;
	return {
		carline,
		salesgroup,
	};
};

const mapDispatchToProps = {
	setCarline,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarsModelSlider);
