import styled from "styled-components";
import { SliderWrapperCommon } from "../../../assets/styles/commonStyles";

export const Wrapper = styled.div`
	${SliderWrapperCommon};
	.slick-slider {
	}
	.slick-list {
	}
	.slick-track {
		display: flex !important;
	}
	.slick-slide {
		height: inherit !important;
		display: flex !important;
		padding: 4px 0;
		> div {
			height: 100%;
		}
	}
`;
